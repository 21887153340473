.flex {
  display: flex;
  gap: 14px;
  margin: 22px 25px;
  align-items: center;
  text-align: left;
}
.flex1 {
  display: flex;
  gap: 15px;

  align-items: center;
  text-align: left;
}
.divs {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 274px;
  left: 399px;
  top: 1456px;
  background: #f2f2f2;
  border-radius: 4px;
}
.align {
  text-align: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 40px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;

  /* Primary Blue */

  color: #01255f;
}
.hr {
  height: 670px !important;
  border: 1px solid #000000;
}
.flexheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 31.25px;
  line-height: 32px;
  /* identical to box height, or 102% */

  display: flex;
  align-items: center;

  /* Primary Orange */

  color: #e74f42;
}
.flexhead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 48.83px;
  line-height: 48px;
  /* or 98% */

  display: flex;
  align-items: center;

  /* Primary Blue */

  color: #01255f;
}
.flexpara {
  
 
  font-weight: 300;
  font-size: 13.8px;
  position: relative;
  line-height: 16px;
  top: 2px;
  display: flex;
  align-items: center;
  color: #000000;
}
.marg {
  margin: 25px 0px;
}
.divsp {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  
  margin-top: 8px;

  display: flex;
  align-items: center;

  color: #ff5f15;
}
.divspara {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14.8px;
  line-height: 16px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #1d1d1d;
}
.imageStyle {
  width: 90%;
  height: 70%;
}
@media (max-width: 500px) {
  .flex {
    flex-direction: column;
  }
  .flex1 {
    flex-direction: column;
  }
  .hr {
    height: 0px !important;
    width: 325px !important;
    border: 1px solid #000000;
  }
}
