.container {
  padding: 20px 60px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 28px;
}

@media (max-width: 500px) {
  .container {
    padding: 20px;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 28px;
  }
}
