.card {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  box-shadow: 0 0 4px #e6e6e6;
  transform: translate(0);
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.card:hover {
  transition: transform 0.3s, -webkit-transform 0.3s;
  transform: translate(0px, -10px);
}

.cardDetails {
  width: 100%;
  background-color: #f2f2f2;
}

.blueTxt {
  color: var(--colorTheme);
  font-size: 20px;
  font-weight: 600;
  padding: 8px;
  padding-bottom: 4px;
}

.cardDetailsBottom {
  display: flex;
  justify-content: space-between;
  padding: 10px 8px;
}

.locationIcon {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 18px;
}
.Image {
  width: 100%;
  height: auto;
}

.moreDetails {
  color: var(--colorTheme);
  cursor: pointer;
  text-decoration: none;
}

.Tag {
  position: absolute;
  top: 40px;
  left: -60px;
  background-color: #e74f42;
  transform: rotate(-45deg);
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  padding: 3px 80px;
}

@media (max-width: 500px) {

  .blueTxt {
    font-size: 24px;
    text-align: left;
    margin-bottom: 4px;
  }
}
