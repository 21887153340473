.carousel-item img {
  object-fit: cover;
  
}
.carousel-item {
  transition: transform 2s ease-in-out !important;
}

.carousel {
  height: 533px;
}

.carousel .carousel-control-next,
.carousel-control-prev {
  bottom: 45%;
  height: 60vh;
}
.carousel-indicators {
  display: none !important;
}
.Link {
  background: green;
  font-size: 17px;
  padding: 15px 10px;
}
.Link:hover{
  color: white;
}
.loc1 {
  filter: brightness(100%) !important;
  position: relative;
  top: -400px;
  display: flex;
  justify-content: flex-end;
  margin-right: 38px;
  margin-bottom: 30px;
  font-size: 56px;
  line-height: 1.2em;
  color: #ffffff;
  font-weight: 600;
}
.price {
  margin-top: 29px;
}
.image {
  filter: brightness(30%);
}
.content {
  width: 28%;
  filter: brightness(100%) !important;
  position: RELATIVE;
  top: -50px;
  right: 78px;
  text-align: center;
  font-size: 23px;
  padding: 38px 0px;
  border-style: solid;
  border-color: green;
}
@media (max-width: 800px) {
  .content {
    width: 64%;
    filter: brightness(100%) !important;
    position: RELATIVE;
    top: -70px;
    right: 30px;
    text-align: center;
    font-size: 23px;
    padding: 38px 0px;
    border-style: solid;
    border-color: green;
  }
}
