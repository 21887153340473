.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
  color: var(--colorHeader);
  border-bottom: 1px solid white;
}
.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:hover {
  color: white;
  text-align: center;
}
#header-elements .nav-item:hover {
  background: #07203d;
  color: #fff;
}
.padding {
  padding: 23px;
}
.botservice {
  margin-bottom: 28px;
}
.colorWhite{
    color: white;
}
.show-mobile-view{
    display: none;
}
@media only screen and (max-width: 600px) {
  .headerwid.px-5 {
    width: 100%;
    text-align: left;
  }
  .show-mobile-view{
    display: block;
  }
  .navbar-dark .navbar-nav .nav-link,
  .navbar-dark .navbar-nav .nav-link:hover {
    color: white;
    text-align: left;
  }
}
