.borderRight {
  border-right: 1px solid black;
}
.desc {
  max-width: 120px;
  font-size: 12px;
}
.connect {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 31.25px;
  line-height: 32px;
  /* identical to box height, or 102% */

  display: flex;
  align-items: center;

  color: #ff5f15;
}
.lorem {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 12.8px;
  line-height: 16px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;

  color: #1d1d1d;
}
.button {
  width: 200px;
  height: 62.27px;
  left: 63px;
  top: 4779.03px;

  background: #ff5f15;
  border: 1px solid #ff5f15;
  border-radius: 4px;
  color: white;
}
.for {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 41.83px;
  line-height: 120%;
  /* or 59px */

  display: flex;
  align-items: center;

  color: #01255f;
}
.wid {
  width: 800px;
}
.flex {
  display: flex;
  text-align: left;
  gap: 69px;
  align-items: center;
}
.gridContainer {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 500px) {
  .secondBanner {
    flex-direction: column;
  }
  .gridContainer {
    grid-template-columns: repeat(1, 1fr);
  }
  .flex {
    flex-direction: column;
    text-align: center;
  }
  .wid {
    width: 320px;
  }
  .borderRight {
    border-right: 1px solid transparent;

    border-bottom: 1px solid black;
  }
}
