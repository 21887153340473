.error {
  width: 100%;
  display: inline-block;
  font-size: 13px;
  margin: 2px 0px 8px 0;
  display: flex;
  align-items: center;
  color: #8e3232;
}
.gap-2 {
  width: 100%;
}
.form-control1 {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #8e3232;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.loc {
  position: relative;
  top: -311px;
  position: relative;
  display: block;
  font-size: 56px;
  line-height: 1.2em;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 10px;
}

.play {
  position: relative;
  bottom: 40px;
  display: block;
  left: 50%;
}
.fa-solid.fa-bars
{
  display: none;
}
.modal-header {
  padding: 2px 22px 0px 0px !important;
}

.modal-content {
  width: auto !important;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-player {
  width: 100%;
  height: auto;
}
.form-control {
  width: 30rem !important;
}
.btn-primary {
  background-color: #ff5f15 !important;
  color: #fff !important;
  border: 0 !important;
  font-size: 20px;
    font-weight: 700;
}
.disp{
  display: inline;
}
.fa-solid.fa-angle-down.angle{
  display: none!important;
}
.fa.fa-envelope.envelope{
padding-right: 22px;
color: #01255F;
}

.fa.fa-phone.phone{
  padding-right: 19px;
  padding-bottom: 19px;
  color: #01255F;
}
@media only screen and (max-width:600px)
{
 .fa-solid.fa-angle-down.angle {width: 100%;
  display: flex !important;
  justify-content: space-between;
  padding: 20px 2px;
  justify-content: space-around;}
    .form-control{
      width:100% !important;
    }
    .modal-content{
      width:90vw !important;
    }
  .fa-solid.fa-bars
{
  display: block;
  color: white !important;
  height: 12px;
  padding: 0px 25px;
}
.navbar-nav.d-flex.justify-content-around.flex-fill{
  position: relative;
    /* top: 18px; */
    z-index: 1;
    width: 100%;
    padding: 0px !important;
    background-color: #08274b;
    /* display: flex; */
    align-items: flex-start;
    border-top: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgb(255 255 255 / 10%);
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 10%);
  
  
}


.disp{
visibility: hidden;
}
}