.accordionContainer {
  padding: 0 20px;
  width: 100%;
}
.accordion {
  margin-left: auto;
  width: 50%;
  position: relative;
}

.accordionOptions {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;
}

.accordionHead {
  padding: 6px 20px;
  color: #fff;
  text-align: center;
  background-color: #e74f42;
  border-radius: 4px;
  position: relative;
  font-size: 14px;
}

.accordionActive {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.option {
  text-align: center;
  border: 1px solid #ccc;
  margin: 0;
  padding: 8px 8px;
  font-size: 14px;
}

.Icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 500px) {
  .accordionContainer {
    display: none;
  }
}
