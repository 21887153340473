.description-heading {
  font-size: 18px;
}

.sub-part {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sub-part p {
  margin: 0;
}

.sub-part p:first-child {
  font-weight: 500;
}
.table-component-2 {
  margin-left: 0;
  margin-top: 20px;
  width: 90%;
}

.table-component-2 tbody tr td {
  text-align: center;
  font-weight: 500;
  background-color: #e8eeff;
  padding: 13px;
  border: 1px solid #fff;
  color: #01255f;
}

.table-component-2 tbody tr th {
  text-align: center;
  background-color: #ff5f15;
  font-weight: 500;
  color: #fff;
  padding: 6px;
  border: 1px solid #fff;
}

.table-component-2 thead tr th {
  background-color: #e74f42;
  font-weight: 500;
  color: #e8eeff;
  padding: 6px;
  border: 1px solid #fff;
  text-align: center;
}

.table-component-2 thead .special-head {
  background-color: #01255f;

  color: #fff;
  font-size: 18px;
  font-weight: 500;
}
