* {
  margin: 0;
  padding: 0;
}
.container {
  margin: 15px 0px;
}
.text1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 39.06px;
  line-height: 150%;
  /* identical to box height, or 59px */

  margin-bottom: 0px !important;
  color: #e74f42;
}
.bot {
  margin-bottom: 0px !important;
}
.hr {
  border-right: 1px solid #000000;
}
.cnt1 {
  text-align: left;
}
.book {
  width: 100%;
  /*  display: flex;
  justify-content: flex-end; */
}
.bookbutton {
  position: relative;
  top: -385px;
  /* width: 391px; */
  /* height: 80px; */

  font-size: 24px;
  /* top: 388px; */
  border-top-left-radius: 50px;
  border: none;
  /* border-radius: 50%; */
  border-bottom-left-radius: 50px;
  color: white;
  padding: 15px 40px;
  background: #e74f42;
  box-shadow: 0px 3px 2px rgb(0 0 0 / 50%);
  border-radius: matrix(-1, 0, 0, 1, 0, 0);
}
.pad {
  padding-top: 17px;
}
.pad1 {
  padding-top: 53px;
}
.icon1 {
  position: relative;
  top: 6px;
  margin-right: 9px;
}
.mar {
  margin-top: 10px;
}
.mar1 {
  margin-right: 30px;
}
.leftCnt{
  padding-right: 25px;
    padding-left: 50px;
    margin-right: 20px;
}
.border {
  padding-right: 23px;
  border-right: 1px solid #000000;
  
}
.button {
  width: 211px;
  color: white;
  height: 61px;
  border: none;
  /* left: 768px; */
  top: 1168px;
  background: #ff5f15;
  border-radius: 8px;
}
.icon {
  position: relative;
  top: 6px;
  margin-right: 8px;
}

.flex {
  display: flex;
  align-items: flex-start;
}

.video {
  cursor: pointer;
}
.spe {
  justify-content: space-evenly;
  padding: 0px 0px 52px 0px;
  position: relative;
  top: -17px;
}
.location{
  position: relative;
  top: 6px;
}
.cnt2 {
  text-align: left;
}
.im {
  width: 100px;
  
}
.icon {
  margin-right: 8px;
  position: relative;
  top: 9px;
}

.text {
  font-family: "Poppins";

  font-style: normal;
  font-weight: 600;
  font-size: 61.04px;
  line-height: 120%;

  color: #01255f;
}
.contactdiv{
 display: none;
}
.ocol{
  color: #1D1D1D;
}
@media only screen and (max-width:413px)
{

  .im {
    width: 148px !important;
    
  }}
  @media only screen and (max-width:281px)
{

  .im {
    width: 105px !important;
    
  }}
@media only screen and (max-width:600px)
{
  .contactdiv{
    position: relative;
    top: 27px;
    display: inline;
  }
  .text1{font-size: 34.06px;}
  .border {
    border-bottom: 1px solid #000000;
    padding: 0px 1px; 
    margin-bottom: 23px;
    margin-right: 23px;
    margin-left: 23px;
    border-right: none;
  }
  .im {
    width: 176px;
    
  }
  .spe{
    flex-direction: column;
  }
  .gap-3 {
    gap: 0.6rem!important;
}
  .text{
    font-size: 37.04px;
  }
  .cnt2{
    margin-right: 23px;
    margin-left: 23px;
  }
}