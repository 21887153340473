.container {
  padding: 20px 60px;
}
.imgCnt {
  width: 100%;
  height: auto;
}
.title{
  display: flex;
  align-items: flex-start;
  gap:8px;
  height: 97px;
}
.headings{
  top: 14px;
    left: 5px;
    position: relative;
    display: block;
    font-size: 36px;
    line-height: 1.2em;
    color: #1a1d1f;
    font-weight: 700;
    text-transform: uppercase;
}

.hr{

  border: 4px solid var(--colorHeader);
  height: 66px !important;
}
.hrow{
  border: 2px solid var(--colorHeader);
  height: 40px !important;
}
.text{
position: relative;
font-weight: normal;

/* top: 45px; */
font-size: 1.25rem;
color: #777777;
background: none;
line-height: 1.2em;
font-family: 'Raleway', sans-serif;}
.color{
  top: 14px;
  left: 5px;
  margin-bottom: 8px;
  position: relative;
  display: block;

  line-height: 1.2em;

  font-weight: 700;
  text-transform: uppercase;
  color: var(--colorHeader);
}
.color1{
  top: 14px;
  left: 5px;
  position: relative;
  display: block;

  line-height: 1.2em;
  color: #1a1d1f;
  font-weight: 700;
  text-transform: uppercase;
 
}
.colors{
  color: var(--colorHeader);
}
.aboutUsText {
  width: 80%;
}
.mission{
  text-align: left;
  padding: 50px 60px;
}

.aboutUs2ndBanner {
  justify-content: space-between;
  align-items: center;
}
.innerbox{
  display: flex;
 
    gap: 10px;

}
@media (max-width: 500px) {
  .aboutUs2ndBanner {
    flex-direction: column-reverse;
  }
  .aboutUsText {
    width: 100%;
  }
}
