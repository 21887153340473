.App {
  text-align: center;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

:root {
  --colorTheme: #01255f;
  --colorHeader: #ff5f15;
  --colorWhite: #fafaf8;
  --colorDarkWhite: #f2f2f2;
}

a{
  text-decoration: none;
}
a{
  color: unset;
}
a:active{
  color:white;
}

/* Remove Arrows in Number Fields */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}