.flex {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #f8f8fa;
}
.color {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 40px;

  color: #e74f42;
}
.color1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 61.04px;
  line-height: 120%;

  color: #01255f;
}
.contactuscont {
  display: flex;
  align-items: flex-start;
  height: 450px;
  background: white;
  justify-content: space-evenly;
  padding-top: 158px;
}
.text {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #01255f;
  text-align: left;
}
.text2 {
  font-style: normal;
  text-align: left;
  font-weight: 600;
  font-size: 25px;
  line-height: 32px;
  /* identical to box height, or 128% */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #01255f;
}
.left {
  text-align: left;
}
.text1 {
  font-style: normal;
  font-weight: 600;
  text-align: left;
  font-size: 25px;
  line-height: 32px;
  /* identical to box height, or 128% */

  display: flex;
  align-items: center;

  /* Primary Orange */

  color: #e74f42;
}
.img {
  color: #01255f;
  margin-right: 15px;
  text-align: left;
  height: 20px;
  width: 20px;
}
.flexbox {
  display: flex;
  align-items: flex-start;
}
.pos {
  position: relative;
  top: 7px;
  margin-right: 7px;
}
.p{
  text-align: left;
}
@media only screen and (max-width: 800px) {
  .contactuscont {
    flex-direction: column;
    padding-left: 31px;
    padding: 32px 0px 32px 34px;
    height: auto;
    display: flex;
    align-items: flex-start;

    background: white;
    justify-content: space-evenly;
  }

  .color {
    order: 2;
    font-size: 23px;
  }
  .color1 {
    font-size: 47px;
  }
  .flex {
    flex-direction: column;
  }
  .imgcon {
    width: 446px;
  }
}

@media only screen and (max-width: 500px) {
  .contactuscont {
    padding: 20px;
    text-align: left;
  }

  .text {
    font-size: 20px;
  }
  .text1 {
    font-size: 20px;
  }
  .text2 {
    font-size: 20px;
  }
}
