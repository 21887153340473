.amazingConstructionCont {
  padding: 50px 60px;
  padding-bottom: 5px;
  text-align: start;
}

.amazingText {
  font-size: 24px;
  color: var(--colorHeader);
  font-weight: 700;
  margin: 0;
}

.ContainerLine {
  display: flex;
  align-items: center;
}

.blueColor {
  color: var(--colorTheme);
  font-size: 48px;
  font-weight: 700;
  margin: 0;
  width: 32%;
}

.orangeLine {
  border-bottom: 4px solid var(--colorHeader);
  width: 70%;
}

@media (max-width: 500px) {
  .amazingConstructionCont {
    padding: 20px;
    padding-bottom: 0;
  }
  .blueColor {
    font-size: 26px;
    width: 62%;
  }
  .orangeLine {
    width: 50%;
  }
}
