.textColorWhite {
  color: white;
}
.font300 {
  font-weight: 300;
}
.font12px {
  font-size: 12px;
}
.font20{
  font-size: 20px;
}
.font90px {
  font-size: 90px;
}
.textAlignLeft {
  text-align: left;

}
.colorHeading {
  color: var(--colorHeader);
}
.colorTheme {
  color: var(--colorTheme);
}
.colorOrange {
  color: var(--colorHeader);
}
.bgOrange {
  background-color: var(--colorHeader);
}
.bgWhite {
  background-color: var(--colorWhite);
}
.bgDarkWhite {
  background-color: var(--colorDarkWhite);
}
.borderRadius4px {
  border-radius: 4px;
}
.textUpperCase {
  text-transform: uppercase;
}

.marginBottom0 {
  margin-bottom: 0px;
}
.marginRight4px {
  margin-right: 4px;
}
.marginAuto {
  margin: auto;
}

.container {
  background-color: var(--colorTheme);
  padding: 0px 25px;
  font-family: "Zilla Slab", serif;
  font-weight: 500;
  font-size: 18.5px;
}
.ComponentsCnt {
  padding: 50px 60px;
}
.borderLine {
  border-top: 4px solid var(--colorHeader);
}

.moreDetails {
  color: var(--colorTheme);
  cursor: pointer;
  text-decoration: none;
}
.flex-1 {
  flex: 1;
}
.flexOnehalf {
  flex: 1.5;
}
.textCenterAndLeft {
  text-align: center;
}
.rightview{
  text-align: right;
  margin-right: 13px;

}
.fontWeight700 {
  font-weight: 700;
}
@media only screen and (max-width: 600px) {
  .textAlignLeft {
    text-align: left;
    padding-right: 0rem !important;
   

  }
  .d-flex{
  
  }
  .homefont{
    font-size: 54px;
  }
  .container {
    padding: 0px;
  }
  .ComponentsCnt {
    padding: 10px 30px;
   
  }
  .dir{
    flex-direction: column !important;
  }
  .largeFont {
    font-size: 48px;
  }
  .smallerfont {
    font-size: 18px;
  }
  .textCenterAndLeft {
    text-align: left;
  }
  .flexAlignCenter {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
