.bg_blue{
    background-color: #08274b;
}
.paddingLeft45{
    padding-left: 45px;
}
.paddingRight35{
    padding-right:35px;
}

@media only screen and (max-width:800px){
    .bg_blue{
        height: 49px; 
        align-items: baseline;
    }  
}