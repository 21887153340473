.cardCnt {
  padding: 45px 30px;
  
}

.borderRight {
  border-right: 1px solid black;
}
.imageStyle{
  height: 100px;
  width: 110px;
}
@media (max-width: 800px) {
  .cardCnt {
    padding: 45px 30px;
    width: 100%;
    height: 280px;
  }

  .borderRight {
    border-bottom: 1px solid black;

    border-right: 1px solid transparent;
  }
}
