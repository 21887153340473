.ourProjectsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 60px;
}

.leftOurProjects {
  text-align: start;
}

.upperSubHeading {
  color: var(--colorHeader);
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}

.lowerHeading {
  color: var(--colorTheme);
  font-size: 48px;
  font-weight: 700;
}

@media only screen and (max-width: 600px) {
  .ourProjectsContainer {
    flex-direction: column-reverse;
    padding: 20px 0;
  }
  .leftOurProjects {
    margin-top: -20px;
    background: rgba(255, 255, 255, 0.55);
    padding: 40px;
    width: 100%;
    text-align: center;
  }

  .lowerHeading {
    font-size: 30px;
  }
}
