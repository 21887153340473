.homeBanner{
    object-fit: cover;
    width: 100%;
   
}
.Link{
    height: 100px;
    width: 50px;
    background: green;
    padding: 10px 29px;
}
.loc1{
    position: relative;
    top: -311px;
    display: flex;
    justify-content: flex-end;
    margin-right: 38px;
    margin-bottom: 30px;
    font-size: 56px;
    line-height: 1.2em;
    color: #ffffff;
    font-weight: 600;
  

}
.price{
    margin-top: 29px;
}
.content{
width: 23%;
 
    POSITION: RELATIVE;
    top: -30px;
    text-align: center;
    font-size: 25px;
    padding: 38px 0px;
    border-style: solid;
    border-color: green;
}
.container{

}
.imgContainer{
    position: relative;
}
.imgBlock{
    background-color: rgba(255, 255, 255, 0.75);
    position: absolute;
    top: 50px;
    left: 50px;
    width: 90%;
    padding-left: 15px;
    padding-bottom: 50px;
}
.bookingBtn{
    padding-left: 60px;
    padding-right: 60px;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
}
.container {
  padding: 80px 60px;
  }
  .imgCnt {
    width: 100%;
    height: auto;
  }
  .title{
    display: flex;
    align-items: flex-start;
    gap:8px;
    height: 97px;
  }
  .headings{
    top: 14px;
      left: 5px;
      position: relative;
      display: block;
      font-size: 36px;
      line-height: 1.2em;
      color: #1a1d1f;
      font-weight: 700;
      text-transform: uppercase;
  }
  
  .hr{
  
    border: 4px solid var(--colorHeader);
    height: 66px !important;
  }
  .hrow{
    border: 2px solid var(--colorHeader);
    height: 40px !important;
  }
  .text{
  position: relative;
  font-weight: normal;
  
  /* top: 45px; */
  font-size: 1.25rem;
  color: #777777;
  background: none;
  line-height: 1.2em;
  font-family: 'Raleway', sans-serif;}
  .color{
    top: 14px;
    left: 5px;
    margin-bottom: 8px;
    position: relative;
    display: block;
  
    line-height: 1.2em;
  
    font-weight: 700;
    text-transform: uppercase;
    color: var(--colorHeader);
  }
  .color1{
    top: 14px;
    left: 5px;
    position: relative;
    display: block;
  
    line-height: 1.2em;
    color: #1a1d1f;
    font-weight: 700;
    text-transform: uppercase;
   
  }
  .colors{
    color: var(--colorHeader);
  }
  .aboutUsText {
    width: 80%;
  }
  .mission{
    text-align: left;
    padding: 50px 60px;
  }
  
  .aboutUs2ndBanner {
    justify-content: space-between;
    align-items: center;
  }
  .innerbox{
    display: flex;
   
      gap: 10px;
  
  }
  @media (max-width: 500px) {
    .aboutUs2ndBanner {
      flex-direction: column-reverse;
    }
    .aboutUsText {
      width: 100%;
    }
  }
  @media (max-width: 800px) {
    .homeBanner{
        object-fit: cover;
        width: 100%;
       height: 700px;
    }
    .imgBlock {
        background-color: rgba(255, 255, 255, 0.75);
        position: absolute;
        top: 50px;
        left: 30px;
        width: 87%;
        padding-left: 15px;
        padding-bottom: 50px;
        font-size: 10px;
    }
    .container {
        padding: 0px;
      }
  }
  