.tabContainer {
  padding: 0 60px;
  display: flex;
  justify-content: flex-end;
}

.tabList {
  display: flex;
  align-items: center;
  gap: 64px;
  font-weight: 500;
  cursor: pointer;
}

.tab {
  padding: 6px 12px;
}

.selectedTab {
  background-color: #e74f42;
  color: white;
  font-weight: 600;
  padding: 6px 12px;
}

@media (max-width: 500px) {
  .tabContainer {
    display: none;
  }
}
