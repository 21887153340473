.imgcnt {
  object-fit: cover;
  width: 100%;
  max-height: 250px;
}

.imgText{
    position:absolute;
    color:white;
    top: 10px;
    left: 5px;
}