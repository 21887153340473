.footer {
    background: #0a0a0a;
    color: #fff;
    position: relative;
    z-index: 5;
    font-size: 14px;
    overflow: hidden;
    font-family: Zilla, serif;
}
.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.copyright {
    font-size: 13px;
    padding: 0.25rem 0;
    background: #101010;
}

.grid {
    display: flex;
    justify-content: space-between;
    padding: 10px 50px 0px 50px;
}
.ul{
    list-style-type: none;
    padding-left: 0px !important;
    list-style-type: none;
    display: flex;
    padding-left: 0px !important;
    flex-direction: column;
    gap: 9px;
    width: 100%;
    
}
.a{
    color: white;
}
.top{
    height: 57px;
    margin-bottom: 34px;
    margin-bottom: 34px;
    width: 100%;
    border-bottom: 1px dotted;
    /* line-height: 50px; */
    padding-bottom: 14px;
    align-items: flex-start;
    display: flex;}
.div{
    height: 45vh;
    width: 21vw;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}
.ocol{
    color: #e86d00;
}
.wid{
width: 17vw;

display: flex;
}
.spans{
    border-bottom: 2px solid transparent;
    padding-bottom: 34px;
    display: inline-flex;
    align-items: flex-start;
    min-height: inherit;
    font-size: 18px;
    margin: 0 0;
}
.span{
    border-bottom: 2px solid #e86d00;;
    padding-bottom: 34px;
    display: inline-flex;
    align-items: flex-start;
    min-height: inherit;
    font-size: 18px;
    margin: 0 0;
}
.span1{
    border-bottom: 2px solid orange;
    padding-bottom: 11px;
    display: inline-flex;
    align-items: flex-start;
    min-height: inherit;
    font-size: 18px;
    margin: 0 0;
}
.grid1 {
    
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 418px;
    padding: 1px 41px;
    gap: 18px;
    margin: 6px 6px;
}
.p{
    position: relative;
    top: 8px;
}
.map{
    position: relative;
    top: 1px;
    right: 13px;
    opacity: 0.5;
}
.contact{
    display: flex;
    flex-direction: column;
    position: relative;
    top: -175px;
    align-items: flex-start;
    gap: 10px;
}
.contact1{
    display: flex;
   

    align-items: center;
    gap: 10px;
  

}
.contact4{
    display: flex;
   border-bottom: 1px dotted;
   justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
width:100%;
border-bottom: 1px dotted;
padding: 0px 8px;
align-items: center;
}
.contact2{
    display: flex;
   
    align-items: flex-start;
    justify-content: space-between;
    width: 12vw;

}
.img{
    height: 44px;
}
@media only screen and (max-width:600px)
{
    .wid{
       
        width: 75vw;
        display: flex;
        }
    .contact2 {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }
    .div{
        width: 100%;
        height: auto;
    }
    .grid1{
        flex-direction: column;
        height:auto;
    padding: 17px 2px;

    }
    .footer{
        overflow: scroll;
    }
    .grid{flex-direction: column;
        align-items: flex-start;
        padding: 4px;}
}




