* {
  margin: 0;
  padding: 0;
}
.footercnt1 {
  background: #01255f;
  color: white;
  height: 309px;

  font-weight: 300;
}
.headings {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 24px;
}
.bot {
  margin-bottom: 0px !important;
}
.bot1 {
  margin-bottom: 3rem !important;
}
.footercnt2 {
  background: #ff5f15;
  color: white;
  height: 70px;
  font-weight: 300;
}
.img {
  margin-right: 14px;
  height: 24px;
}
.flex {
  display: flex;
  align-items: flex-start;
}
.footer1 {
  text-align: left;
}
.jus {
  justify-content: space-between;
  padding: 18px 56px 28px 56px;
  align-items: flex-start;
}
.right {
  margin-right: 12px;
  position: relative;
  top: 5px;
}
.top {
  padding-top: 45px;
}
.bottom {
  margin-bottom: 15px;
}
.bottom1 {
  margin-bottom: 10px; 
}
.bottom1 a{
    color:white;
}

.top1 {
  padding-top: 25px;
}
.list {
  list-style: none;
  padding-left: 0px !important;
}
